<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img :src="require('../assets/logobelakasa.png')" class="my-3" contain height="169" />
      </v-col>

    </v-row>
    <v-row class="text-center">
      <v-col cols="12">
        <v-form @submit.prevent="enviar()" ref="meuform">
          <v-file-input v-model="meuform.arquivo" color="deep-purple accent-4" counter label="Arquivo original em XML"
            placeholder="Selecione seu arquivo XML" prepend-icon="mdi-paperclip" outlined :show-size="1000"
            :rules="[(value) => !!value || 'Requerido.']"
                     hint="Arquivo em XML">
            <template v-slot:selection="{ index, text }">
              <v-chip v-if="index < 2" color="deep-purple accent-4" dark label small>
                {{ text }}
              </v-chip>

              <span v-else-if="index === 2" class="text-overline grey--text text--darken-3 mx-2">
                +{{ arquivo.length - 2 }} File(s)
              </span>
            </template>
          </v-file-input>
          <v-row>
            <v-col>
              <v-btn class="my-5" color="blue" elevation="2" large x-large outlined @click="enviar()" :loading="loading" :disabled="loading || !meuform.arquivo">
                <v-icon large>
                  mdi-file-sync-outline
                </v-icon>
                 Converter Arquivo
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-if="file">
            <v-col>
              <v-btn class="my-5" color="green" large x-large outlined @click="abrirArquivo()" >
                <v-icon large>
                  mdi-file-download
                </v-icon> 
                Salvar arquivo
              </v-btn>
            </v-col>
          </v-row>

        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'HelloWorld',

    data: () => ({
      file: null,
      loading:false,
      meuform: {},
      transporte: {cabecalho:[]},
    }),
    methods: {
      abrirArquivo() {
        window.open(`${window.location.protocol}//${window.location.hostname}/${this.file}`);  
      },
      novo: async function () {
        this.loading = true;
        return axios
          .post(`${window.location.protocol}//${window.location.hostname}/process.php `,
            this.transporte.formulario, {
            headers: this.transporte.cabecalho,
          })
          .then((response) => {           
            if(!response.data.file){
              throw response.data; 
            }
            this.file = response.data.file;
            this.$toasted.show('Arquivo convertido com sucesso', {
              type: "success",
              duration: 5000,
            });

          })
          .catch((error) => {
          this.$toasted.show(error.error,{type:'error',duration : 5000});         
          })
          .finally(() => {
            this.loading = false;
          });
      },
      async enviar() {
        let upload = Object.keys(this.meuform).find((e) => {
          return this.meuform[e] instanceof File;
        });

        if (upload) {
          var formData = new FormData();
          Object.keys(this.meuform).forEach((k) => {
            if (
              this.meuform[k] instanceof Object &&
              !(this.meuform[k] instanceof File)
            ) {
              formData.append(k, JSON.stringify(this.meuform[k]));
            } else {
              formData.append(k, this.meuform[k]);
            }
          });
          this.transporte.cabecalho[
            "Content-Type"
          ] = `multipart/form-data; boundary=${formData._boundary}`;
          this.transporte.formulario = formData;
          await this.novo();
        }
      }
    },
  }
</script>

<style>
  body {
    background-color: #000000;
  }
</style>